.privacy-page{
    margin-top: 100px;
}
.privacy-page-title-container{
    height:10vh;
    background-color: #173269;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8vh;
}


.privacy-page-title{
    color:grey;
    font-family: 'Quicksand', sans-serif;
    font-size: 2vmin;
    width:100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.privacy-page-content{
    display: flex;
    flex-direction: column;
    flex-direction: column;
    align-items: center;
}

.privacy-page-section-title{
    margin: 0 15vw 2vh 15vw;
    color:black;
    font-family: 'Quicksand', sans-serif;
    font-size: 2.5vmin;
    font-weight:500;
}

.privacy-page-section-text{
    color:black;
    font-family: 'Quicksand', sans-serif;
    font-size: 1.5vmin;
    text-align: center;
    margin: 0 15vw 3vh 15vw;

}