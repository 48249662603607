.demo-footer{
    background-color: #173269;
    position:relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8vh 0 8vh 0;
}

.top-and-arrow-container{
    color:white;
    font-family: 'Quicksand', sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    position:absolute;
    right:5vw;
    bottom:5vh;
}

.get-ready-for-neddie{
    font-size: 4vmin;
    font-weight: 700;
    margin-bottom: 3vh;
    }

.subscribe-text{
        font-size: 2.5vmin;
        font-weight: 600;
        margin-bottom: 3vh;
    }

.get-ready-for-neddie-container{
        color:white;
        font-family: 'Quicksand', sans-serif;
    }

.footer-form-container{
    display: flex;
    flex-direction: row;
    }

.footer-subscribe-input{
    width: 20vw;
    font-size:1.5vmin;
    text-align: left;
    color: #4592FF;
    padding: 1.5vh 1vw 1.5vh 1vw;
    margin-right:1vw;
    border-radius: 10px;
    background-color: #F2F6F8;
    border: solid 2px #4592FF; 
}

.subscribe-button{
    text-decoration: none;
    background-color: #4592FF;
    color:white;
    font-family: 'Quicksand', sans-serif;
    font-size: 1.9vmin;
    font-weight: 600;
    padding: .5vh 2vw .5vh 2vw;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.top{
    font-size: 1.5vmin;
    font-weight: 600;
}

.top-light{
 color: #406DC9;
}

.top-arrow{
    width: 2.3vw;
    margin-left:.5vw; 
}

.top-arrow:hover{
    cursor: pointer;
}


@media only screen and (max-width : 768px) {

    .demo-footer{
        background-color: #173269;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 8vh 0 7vh 0;
        margin: -2px 
      }
    
    .top-and-arrow-container{
        color:white;
        font-family: 'Quicksand', sans-serif;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: static;
        margin: 15% 0 0% 0;
    }
    
    .get-ready-for-neddie{
        font-size: 6vmin;
        font-weight: 700;
        margin-bottom: 3vh;
        }
    
    .subscribe-text{
            font-size: 3.8vmin;
            font-weight: 600;
            margin-bottom: 3%;
        }
    
    .get-ready-for-neddie-container{
            color:white;
            font-family: 'Quicksand', sans-serif;
        }
    
    .footer-form-container{
        display: flex;
        flex-direction: row;
        }
    
    .footer-subscribe-input{
        width: 45vw;
        font-size:3.5vmin;
        text-align: left;
        color: #4592FF;
        padding: 3% 1% 3% 1%;
        margin-right:1vw;
        border-radius: 10px;
        background-color: #F2F6F8;
        border: solid 2px #4592FF; 
    }
    
    .subscribe-button{
        text-decoration: none;
        background-color: #4592FF;
        color:white;
        font-family: 'Quicksand', sans-serif;
        font-size: 3.8vmin;
        font-weight: 600;
        padding: .5% 30px .5% 30px;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .top{
        font-size: 3.8vmin;
        font-weight: 500;
    }

    .top-light{
        font-size: 3.8vmin;
        font-weight: 500;
    }
    
    .top-arrow{
        width: 9vw;
        margin-left:15px; 
    }

}