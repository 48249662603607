#about-us-wrapper h2 {
  color: #173269;
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  font-size: 5vmin;
  margin-bottom: 0.6em;
}

#who-we-are {
  margin-top: 100px;
  margin-bottom: 60px;
  text-align: center;
  background-color: #f2f6f8;
  padding: 10% 0 8% 0;
  overflow: hidden;
  clip-path: polygon(0 15%, 100% 0, 100% 85%, 0% 100%);
}

#who-content {
  width: 50ch;
  text-align: left;
  margin: auto;
  font-family: 'Quicksand', sans-serif;
  color: #173269;
  font-size: clamp(1.5rem, 2vw, 6.0rem);
  font-weight: 600;
}

.meet-the-team {
  text-align: center;
  margin-bottom: 8rem;
  padding: 0 10%;
}

.team-card-wrapper {
  width: 95%;
  margin: auto;
}

.team-card-area {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  grid-gap: 2%;
}

/* Footer modifications */

.about-footer div.get-ready-for-neddie-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-footer > div.demo-footer {
  background-color: white;
}

.about-footer div.subscribe-text {
  color: #173269;
}

.about-footer .get-ready-for-neddie {
  color: #406dc9;
}

.about-footer .footer-subscribe-input {
  background-color: white;
}

.about-footer div.footer-nav-container {
  background-color: white;
}

.about-footer div.footer-nav-container > div > a {
  color: #173269;
}

.about-footer div.footer-nav-container > div {
  border-bottom: 2px solid #d9dadb;
  border-top: 2px solid #d9dadb;
}

.team-card-wrapper-mobile {
  display: none;
}

#board-of-advs {
  margin: 10% 0;
}

#BoA .team-card-wrapper {
  line-height: 2.5vw
}

/* #BoA .team-card-wrapper-mobile {
  line-height: 5vw
} */

@media only screen and (max-width: 768px) {
  #about-us-wrapper h2 {
    font-size: 8vmin;
  }

  #who-we-are {
    clip-path: polygon(0 10%, 100% 0, 100% 90%, 0% 100%);
  }

  #who-text {
    margin: 3vmin 0
  }

  #who-content {
    width: 90vw;
    font-size: 4.2vmin;
  }

  .meet-the-team {
    padding: 0;
    margin-bottom: 0;
  }

  .meet-the-team > h2 {
    margin-bottom: 12%;
  }

  .team-card-wrapper {
    display: none;
  }

  .team-card-wrapper-mobile {
    display: static;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .team-card-area-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
