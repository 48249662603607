.carousel-card{
    margin-top:20vh;
    width:100vw;
    position:relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items:center;
}

.carousel-card-arrows-and-info-container{
    position:absolute;
    background-color: #173269;
    color:white;
    font-family: 'Quicksand', sans-serif;
    top:8vmin;
    left:0;
    margin-left:5vw;
    padding: 3vmin 2vw 4vmin 2vw;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 32vw;
    min-width: 500px;
}

.carousel-text-container{
    width: 90%;
    height: 18vh;
    padding: 0 5% 4vh 5%;
}

.carousel-title{
    font-size: 4vmin;
    font-weight: 500;
    margin-bottom: 2vh;
}

.carousel-desc{
    text-align: left;
    font-size: max(min(0.5vw, 0.5em), 1.9vmin);
  }

.carousel-image{
    width:65vw;
    height: 75vh;
    background-position: center;
    background-size: cover;
}

.carousel-arrow{
    width:3.5vh;
}

.carousel-arrow:hover{
   cursor: pointer;
}