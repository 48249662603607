.person {
  padding-bottom: 100%;
  position: relative;
}

@keyframes fade {
  0% {
    opacity: 0%;
  }
}

.name-title {
  position: absolute;
  color: white;
  width: 100%;
  text-align: center;
  bottom: .4rem;
  font-family: 'Quicksand', sans-serif;
  font-size: 2.2vw;
  font-weight: 800;
}

.person-title-nonexp {
  width: 80%;
  margin: auto;
}

.plus-wrap {
  height: 2.2vw;
  width: 2.2vw;
  padding: 1vw;
  position: absolute;
  bottom: 0.5vw;
  right: 0.5vw;
  transition: transform .5s
}

.plus-wrap svg {
  width: 2.2vw;
  position: absolute;
  top: 0;
  left: 0
}

.expanded .plus-wrap {
  transform: rotate(135deg);
}

svg {
  stroke: white;
  stroke-width: 6%;
  fill: white;
}

.fun-img {
  width: 32%;
  height: 100%;
  position: absolute;
}

.expanded-content {
  width: 68.5%;
  height: 75%;
  margin-top: 3%;
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  font-family: 'Quicksand', sans-serif;
  text-align: left;
  overflow-y: auto;
}

.expanded-content > div > div > div {
  background-color: white !important
}

.expanded-content::-webkit-scrollbar {
  display: none;
}

.e-content-wrap {
  padding: 0 10% 0 10%;
}

.linkedin-icon {
  position: relative;
  top: -.2rem
}


.linkedin-icon > svg{
  width: 3vmin;
  margin-left: 1rem;
  stroke: none
}

.person-content{
  padding-bottom: 1rem;
  font-size: 2.5vmin
}