.banner-image{
    height:95vh;
    background-position: center;
    background-size: cover;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    width: 100vw;
    float: left;
}

@keyframes slider {
    0%{
        left:0;
    }
    25%{
        left:0
    }
    30%{
        left:-100vw;
    }
    60%{
        left:-100vw; 
    }
    65%{
        left:-200vw; 
    }
    95%{
        left:-200vw; 
    }
    100%{
        left:-300vw;
    }
}

.banner-carousel-container{
    overflow: hidden;
}

.banner-carousel-container figure{
    height:95vh;
    margin: 0;
    left: 0;
    position:relative;
    width:400vw;
    animation: 15s slider infinite;
}

@media only screen and (max-width : 768px) {

    .banner-image{
        height:55vh;
        background-position: center;
        background-size: cover;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
        width: 100vw;
        float: left;
    }



    .banner-carousel-container figure{
        height:40vh;
        margin: 0;
        left: 0;
        position:relative;
        width:400vw;
        animation: 15s slider infinite;
    }
}

