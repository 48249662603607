.footer-nav-container{
    background-color: #173269;
    padding: 5vh 5vw 5vh 5vw;
    position: relative;
}

.footer-nav-container-light{
    background-color: white;
    padding: 5vh 5vw 5vh 5vw;
    position: relative;
}

.footer-nav{
    border-bottom: 2px solid white;
    border-top: 2px solid white;
    padding: 5vh 0 5vh 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer-nav-light{
    border-bottom: 2px solid #D9DADB;
    border-top: 2px solid #D9DADB;
    padding: 5vh 0 5vh 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer-nav-link{
    text-decoration: none;
    color:white;
    font-family: 'Quicksand', sans-serif;
    font-size: 1.6vmin;
    font-weight: 600;
    margin-right: 2vw;
}

.footer-nav-link-light{
    text-decoration: none;
    color:#173269;
    font-family: 'Quicksand', sans-serif;
    font-size: 1.6vmin;
    font-weight: 600;
    margin-right: 2vw;
}

.social-media-icons{
    margin-left:auto;
    margin-right: 2vw;
}

.social-media-icon{
    width: 6vmin;
    height: 6vmin;
    margin: 0 1vw 0 1vw;
}

.copyright-statement{
    color:white;
    font-family: 'Quicksand', sans-serif;
    font-size: 1vmin;
    text-align: right;
    position:absolute;
    right:5vw;
    bottom:3vh;
}

.copyright-statement-light{
    color:#173269;
    font-family: 'Quicksand', sans-serif;
    font-size: 1vmin;
    text-align: right;
    position:absolute;
    right:5vw;
    bottom:3vh;
}

@media only screen and (max-width : 768px) {

    .footer-nav-container{
        background-color: #173269;
        padding: 5vh 5vw 5vh 5vw;
        position: relative;
    }
    
    .footer-nav-container-light{
        background-color: white;
        padding: 5vh 5vw 5vh 5vw;
        position: relative;
    }
    
    .footer-nav{
        border-bottom: 2px solid white;
        border-top: 2px solid white;
        padding: 5vh 0 5vh 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .footer-nav-light{
        border-bottom: 2px solid #D9DADB;
        border-top: 2px solid #D9DADB;
        padding: 5vh 0 5vh 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .nav-footer-links-wrapper {
      display: grid;
      grid-template-columns: auto auto auto;
    }

    .footer-nav-link{
        font-size: 3.5vmin;
        font-weight: 500;
        padding: 0 2.5vw 2.5vw 2.5vw
    }

    .footer-nav-link-light{
        font-size: 3.5vmin;
        font-weight: 500;
        padding: 0 1vw 0 1vw
    }
    
    .social-media-icons{
        margin: 7% 0 0 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    
    .social-media-icon{
        width: 10vw;
        height: 10vw;
        margin: 0 3vw 0 3vw;
    }
    
    .copyright-statement{
        color:white;
        font-family: 'Quicksand', sans-serif;
        font-size: 2.5vmin;
        text-align: right;
        position:absolute;
        right:5vw;
        bottom:2vh;
    }
    
    .copyright-statement-light{
        color:#173269;
        font-family: 'Quicksand', sans-serif;
        font-size: 2.5vmin;
        text-align: right;
        position:absolute;
        right:5vw;
        bottom:2vh;
    }







}