.request-demo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #173269;
  font-family: 'Quicksand', sans-serif;
  padding: 5vh 5vw 5vh 5vw;
}

.be-a-part-of-neddie-container {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.be-a-part-of-neddie-title {
  text-align: left;
  font-size: 5vmin;
  font-weight: 700;
  margin-bottom: 2vh;
}

.be-a-part-of-neddie-text {
  font-size: 3vmin;
  font-weight: 600;
  text-align: left;
}

.request-demo-form-container {
  padding: 5vh 4vw;
  background-color: white;
  border-radius: 25px;
  margin-left: 5vw;
  flex-grow: 100;
  min-width: 45vw;
}

.request-demo-form {
  display: flex;
  flex-direction: column;
}

.request-form-label {
  font-family: 'Quicksand', sans-serif;
  color: #2c2c2c;
  font-size: 2.5vmin;
  font-weight: 600;
  text-align: left;
  margin-bottom: 1vh;
}

.request-form-input {
  font-size: 1.5vmin;
  text-align: left;
  color: #2c2c2c;
  padding: 1.5vh 1vw 1.5vh 1vw;
  margin-bottom: 3vh;
  border-radius: 10px;
  background-color: #f2f6f8;
  border: none;
}

#root
  > div
  > div.section-eight
  > div
  > div.request-demo-form-container
  > form
  > a {
  display: inline-block;
  margin: auto;
  padding: 1.2vh 1.8vw 1.2vh 1.8vw;
}

.request-form-select {
  color: #2c2c2c;
  display: block;
  font-size: 1.5vmin;
  font-family: 'Quicksand', sans-serif;
  color: #444;
  padding: 1.5vh 1vw 1.5vh 1vw;
  box-sizing: border-box;
  border-radius: 10px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  background-color: #f2f6f8;
  border: 0px;
  outline: 0px;
  margin-bottom: 5vh;
}

.request-form-select::-ms-expand {
  display: none;
}

.request-form-select:focus {
  color: #222;
  outline: none;
}
.request-form-select option {
  font-weight: normal;
}

@media only screen and (max-width: 768px) {
  .request-demo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5% 5% 5% 5%;
  }

  .be-a-part-of-neddie-container {
    align-items: center;
    margin: 5% 0 9% 0;
  }

  .be-a-part-of-neddie-title {
    text-align: left;
    font-size: 7vmin;
    margin-bottom: 2vh;
  }

  .be-a-part-of-neddie-text {
    font-size: 4vmin;
    text-align: center;
    padding: 0 5% 0 5%;
  }

  .request-demo-form-container {
    padding: 6vh 4vw 5vh 4vw;
    background-color: white;
    border-radius: 25px;
    margin: 0 5% 0 5%;
    flex-grow: 100;
    min-width: 80%;
  }

  #root
    > div
    > div.section-eight
    > div
    > div.request-demo-form-container
    > form
    > a {
    display: inline-block;
    margin: auto;
    font-size: 3.8vmin;
    padding: 2% 4% 2% 4%;
  }

  .request-form-label {
    font-size: 4vmin;
  }

  .request-form-input {
    font-size: 3vmin;
  }

  .request-form-select {
    font-size: 2.5vmin;
  }
}
