.team-card-mobile {
  height: 70vw;
  width: 70vw;
  position: relative;
  background-color: #173269;
  border-radius: 15px;
  margin-bottom: 15%;
}

.team-card-picture-mobile {
  height: 70vw;
  width: 70vw;
  background-size: cover;
  background-position: center;
  position: absolute;
  bottom: 3vw;
  right: 3vw;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.team-card-bio-mobile {
  background-image: linear-gradient(rgb(23, 50, 105, 0), rgb(23, 50, 105, 0.8));
  height: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 0 0 15px 15px;
}

.team-card-bio-box-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto 0 auto;
  line-height: 5vw;
  width: 70%  
}

.team-card-name-mobile {
  font-family: 'Quicksand', sans-serif;
  color: white;
  font-size: 5vmin;
  font-weight: 800;
}

.team-card-title-mobile {
  font-family: 'Quicksand', sans-serif;
  color: white;
  font-size: 5vmin;
  font-weight: 600;
}

.team-card-link-mobile {
  align-self: flex-end;
  margin: 0 4% 4% 0;
}

.team-card-linkedin-mobile {
  height: 5vw;
  width: 5vw;
  stroke: none;
}
