.nav-tab {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 6vw;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
  font-family: 'Quicksand', sans-serif;
  display: flex;
  align-items: center;
  z-index: 100;
  transition: background 0.2s, color 0.2s;
}
.nav-gradient {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
}

#scroll-style {
  background-color: white;
  background-image: none;
  color: black;
  transition: background 0.2s, color 0.2s;
}

#scroll-style .neddie,
#scroll-style .nav-menu,
#scroll-style path {
  color: #406dc9;
  fill: #406dc9;
  transition: background 0.2s, color 0.2s;
}

#scroll-style .nav-request-demo {
  background-color: #406dc9;
  color: white;
  transition: background 0.2s, color 0.2s;
}

.nav-tab svg {
  width: .8vw;
  /* margin-left: ; */
  transition: background 0.2s, color 0.2s;
}

.neddie {
  margin-left: 5vw;
  text-decoration: none;
  color: white;
  font-size: 2.4vw;
  font-weight: 600;
  transition: background 0.2s, color 0.2s;
}
.neddie:hover {
  text-decoration: none;
  color: white;
}

.dropdown-and-request-button {
  margin: 0 5vw 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%
}

.nav-menu,
.nav-menu:focus {
  font-size: 1.4vw;
  font-weight: 600;
  color: white;
  outline: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  transition: background 0.2s, color 0.2s;
}
#burger {
  height: 2.2rem;
  width: 2.2rem;
}

#dropdown-list {
  background: white;
  padding: .5vw;
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: calc(6vw * .9);
  animation: 0.1s linear 0s 1 fadeIn;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#dropdown-list * {
  border: none;
  background: white;
  /* border-radius: 8px; */
  padding: .7vw 1.5vw;
  min-width: 180px;
  text-decoration: none;
  color: #406dc9;
  text-align: center;
  font-size: 1.4vw;
}

.nav-request-demo {
  text-decoration: none;
  color: #173269;
  font-size: clamp(.8rem, 1.2vw, 5rem);
  font-weight: 600;
  background-color: white;
  padding: 0.6vw 1.8vw;
  border-radius: clamp(2rem, 1.4vw, 4rem);
  margin-left: 3vw;
  transition: background 0.2s, color 0.2s;
}

.nav-request-demo:hover {
  text-decoration: none;
}

.mobile-request-btn {
  display: none;
}

@media only screen and (max-width: 768px) {
  .neddie {
    font-size: 7vw;
  }
  .nav-tab {
    height: 75px;
  }
  .mobile-request-btn {
    display: block;
  }
  .desktop-request-btn {
    display: none;
  }
  .nav-menu {
    position: static
  }
  #dropdown-list {
    width: 70vw;
    position: absolute;
    right: 0;
    top: 70px;
    border-radius: 0 0 2rem 2rem;
    box-shadow: -5px 10px 25px -25px #000, 5px 10px 25px -25px #000;
    padding-bottom: 5%;
  }
  #dropdown-list * {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 800;
    padding: 2.2vw .5vw;
    color: #173269
  }
  .nav-request-demo {
    border-radius: 2rem;
    padding-left: 4rem !important;
    padding-right: 4rem !important;
    background-color: #173269 !important;
  }
}
