.App {
  text-align: center;
  color:black;
  width: 100%;
  overflow: hidden;
}

/* ************* */
/* beginning of section one (banner) */
/* ************* */

.section-one{
  /* height:95vh; */
  position: relative;
  /* margin-top:0; */
}

.banner-description{
  position:absolute;
  bottom: 0;
  height: 30%;
  width: 100%;
  display: flex;
  background-image: linear-gradient( rgba(0,0,0,0), rgba(0,0,0,0.6));
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding: 0 5% 5vh 5%;
}

.banner-description-title{
  font-family: 'Quicksand', sans-serif;
  color:white;
  font-size: 6vmin;
  font-weight: 700;
  margin-bottom: 1vh;
}

.banner-description-subtitle{
  font-family: 'Quicksand', sans-serif;
  color:white;
  font-size: 3vmin;
  font-weight: 800;
  margin-bottom:2vh
}

.see-how-it-works-button{
  text-decoration: none !important;
  background-color: #406DC9;
  color:white;
  font-family: 'Quicksand', sans-serif;
  font-size: 1.9vmin;
  font-weight: 600;
  padding: 2vh 2vw 2vh 2vw;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.see-how-it-works-button:hover{
  color: white
}
/* ************* */
/* beginning of section two (what we offer cards) */
/* ************* */

.section-two{
  display: flex;
  background-color: white;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin-bottom:15vh;
}

.what-we-offer{
  color:#406DC9;
  font-family: 'Quicksand', sans-serif;
  font-size: 5vmin;
  font-weight: 700;
  margin: 9vh 0 3vh 0;
}

.what-we-offer-row{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

/* *********** */
/* beginning of section three  nonprofit callout*/
/* ************ */

.nonprofit-callout-mobile-wrapper{
  display: none;
}

.top-cut-svg{
  width: 100%;
  display:none
}


.nonprofit-callout-desktop-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nonprofit-callout{
  text-align: left;
  margin: 0 0 0 5vw;
}

.nonprofit-callout-title{
  font-family: 'Quicksand', sans-serif;
  font-size: 5vmin;
  font-weight: 700;
  color:#406DC9;
  margin-bottom: 4vh;
}

.nonprofit-callout-desc{
  font-family: 'Quicksand', sans-serif;
  font-size: 2.5vmin;
  font-weight: 600;
  color: black;
  margin-bottom: 2vw;
}

.nonprofit-callout-img{
  height: 100%;
  width: 50vw;
  margin-right: 5vw;
  padding: 0 0 0 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.laptop1{
  width:55vw;
}

/* *********** */
/* beginning of section four (Donor Callout) */
/* ************ */

.donor-callout-mobile-wrapper{
display: none;
}


.donor-callout-desktop-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
}


.donor-callout{
  text-align: left;
  margin: 0 5vw 0 5vw;
  box-sizing: border-box;

}

.donor-callout-title{
  font-family: 'Quicksand', sans-serif;
  font-size: 5vmin;
  font-weight: 700;
  color:#406DC9;
  margin-bottom: 4vh;
}

.donor-callout-desc{
  font-family: 'Quicksand', sans-serif;
  font-size: 2.5vmin;
  font-weight: 600;
  color: black;
  margin-bottom: 2vw
}

.donor-callout-img{
  height: 100%;
  width: 50vw;
  margin-left: 5vw;
  padding: 5vh 5vw 5vh 5vw;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.phones{
  width: 32vw;
}

/* ************ */
/* Beginning of section five (business callout) */
/* ************ */

.business-callout-desktop-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.business-callout-mobile-wrapper{
  display:none;
}

.business-callout{
  text-align: left;
  margin: 0 0 0 5vw;
}

.business-callout-title{
  font-family: 'Quicksand', sans-serif;
  font-size: 5vmin;
  font-weight: 700;
  color:#406DC9;
  margin-bottom: 4vh;
}

.business-callout-desc{
  margin-bottom: 2vw;
  font-family: 'Quicksand', sans-serif;
  font-size: 2.5vmin;
  font-weight: 600;
  color: black;
}

.business-callout-img{
  height: 100%;
  width: 50vw;
  margin-right: 5vw;
  padding: 0 0 0 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.laptop2{
  width:55vw;
}

.bottom-cut-svg{
  width: 100%;
  display:none
}

/* ************ */
/* beginning of section six (vission/mission carousel) */
/* ************ */

.section-six{
margin-bottom: 20vh;
}

/* ************ */
/* beginning of section seven (Partner and invest) */
/* ************ */
.section-seven{
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 margin-bottom: 25%;
}

.partner-and-invest-wrapper{
  display: flex;
  flex-direction: row;
  margin: 0 5% 0 5%;
}

.hands-in-image{
  width: 100%;
  background-position: center;
  background-size: cover;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}

.partner-and-invest-text-wrapper{
  align-self: center;
  display: flex;
  flex-direction: column;
  padding: 0 2vw 0 5vw;
  font-family: 'Quicksand', sans-serif;
}

.partner-and-invest-title{
  color: #406DC9;
  font-size: 2.8vmin;
  font-weight: 700;
  margin-bottom: 5vh
}

.partner-and-invest-text{
  text-align: left;
  color: #6C6C6C;
  font-size: 2.2vmin;
  font-weight: 500;
  margin-bottom: 4vh
}

#root > div > div.section-seven > div > div > div.contact-us-wrapper > a{
  display: inline-block;
  margin:auto;
  padding: 1.2vh 1.8vw 1.2vh 1.8vw;
}

.app-mobile-only {
  display: none
}

.get-started-now, .get-started-now:hover {
  align-self: center;
  padding: 1vw 2vw;
  background-color: #406dc9;
  color: white !important;
  border-radius: 4vw;
  font-size: 1rem;
  font-size: clamp(1rem, 2.2vmin, 10rem);
  font-weight: 600;
  text-decoration: none
}

/* this will encompass 99% of phones and some tablets. */
@media only screen and (max-width : 768px) {

/* ************* */
/* beginning of section one (banner) */
/* ************* */

.section-one{
  margin-top: 75px;
  height:55vh;
}

.app-desktop-only {
  display: none
}

.app-mobile-only {
  display: block
}

.banner-description{
  height: auto;
  padding-bottom: 0;
  position: absolute;
  /* font-size: 2rem; */
}

.banner-description-title{
  font-size: 6vw;
}

.banner-description-subtitle{
  font-size: 4.5vw;
}

.see-how-it-works-button{
  display: none;
  font-size: 4vmin !important;
  padding: 2vmin 8vmin !important
}

/* ************* */
/* beginning of section two (what we offer cards) */
/* ************* */

.section-two{
  margin-bottom:0%;
}

.what-we-offer{
  font-size: 2rem;
  margin: 10% 0 2% 0;
}

.what-we-offer-row{
  flex-direction: column;
}

/* *********** */
/* beginning of section three  nonprofit callout*/
/* ************ */

.top-cut-svg{
  width: 100%;
  display:block;
}

.nonprofit-callout-mobile-wrapper{
  display:flex;
  flex-direction: column;
  align-items: center;
  background-color: #F2F6F8;
}

.get-started-now {
  padding-left: 6vw;
  padding-right: 6vw;
  margin-bottom: 8vw
}

.nonprofit-callout-desktop-wrapper{
  display:none;
}

.nonprofit-callout{
  margin: 0 0 0 0;
}

.nonprofit-callout-title{
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  color:#406DC9;
  margin-bottom: 6%;
}

.nonprofit-callout-desc{
  font-size: 1rem;
  font-weight: 600;
  color: #173269;
  text-align: left;
  padding: 0 15% 0 15%;
}

.nonprofit-callout-img{
  height: 40%;
  width: 100%;
  margin-right: 0;
  padding: 0 0 0 0;
}

.laptop1{
  width:80%;
}

/* *********** */
/* beginning of section four (Donor Callout) */
/* ************ */


.donor-callout-desktop-wrapper{
  display: none;
  }

.donor-callout-mobile-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F2F6F8;
  }

.donor-callout{
  text-align: center;
  margin: 0 0 0 0;

}

.donor-callout-title{
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  color:#406DC9;
  margin-bottom: 10%;
}

.donor-callout-desc{
  font-size: 1rem;
  font-weight: 600;
  color: #173269;
  text-align: left;
  padding: 3% 15% 10% 15%;
}

.donor-callout-img{
  height: 40%;
  width: 100%;
  margin: 0;
  padding: 0 0 0 0;
}

.phones{
  width: 60%;
}

/* ************ */
/* Beginning of section five (business callout) */
/* ************ */

.business-callout-desktop-wrapper{
  display: none;
}

.business-callout-mobile-wrapper{
  display:flex;
  flex-direction: column;
  align-items: center;
  background-color: #F2F6F8;
}

.business-callout{
  text-align: left;
  margin: 0 0 0 5vw;
}

.business-callout-title{
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  color:#406DC9;
  margin: 2% 0 10% 0;
}

.business-callout-desc{
  font-size: 1rem;
  font-weight: 600;
  color: #173269;
  text-align: left;
  padding: 3% 15% 10% 15%;
}

.business-callout-img{
  height: 40%;
  width: 100%;
  margin: 0;
  padding: 0 0 0 0;

}

.laptop2{
  width:80%;
}

.bottom-cut-svg{
  width: 100%;
  display:block;
}

/* ************ */
/* Beginning of section six vision carousel */
/* ************ */
.section-six{
  display: none;
}

/* ************ */
/* beginning of section seven (Partner and invest) */
/* ************ */

.section-seven{
  margin: 0% 0 10% 0;
}
 
 .partner-and-invest-wrapper{
   display: flex;
   flex-direction: row;
   margin: 0 15%
 }
 
 .hands-in-image{
   display:none;
 }
 
 .partner-and-invest-text-wrapper{
   padding: 0
 }
 
 .partner-and-invest-title{
   color: #406DC9;
   font-size: 1.6rem;
   font-weight: 600;
   margin: 1.3rem 0;
 }
 
 .partner-and-invest-text{
   text-align: left;
   color: #6C6C6C;
   font-size: 1rem;
   color: #173269;
   font-weight: 600;
   margin-bottom: 4vh
 }
 
 #root > div > div.section-seven > div > div > div.contact-us-wrapper > a{
   display: inline-block;
   margin:auto;
   font-size: 3vmin;
   padding: 1.2% 4% 1.2% 4%;
 }

}


