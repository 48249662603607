#root > div > div.request-demo-container > div.request-demo-form-container > form > a{
    display: inline-block;
    margin:auto;
    padding: 1.2vh 1.8vw 1.2vh 1.8vw;
}

div.contact-us-page-wrapper > div.demo-footer > div.get-ready-for-neddie-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

div.contact-us-page-wrapper > div.demo-footer{
    background-color: white;
}

div.contact-us-page-wrapper > div.demo-footer > div.get-ready-for-neddie-container > div.subscribe-text{
    color:#173269;
}

div.contact-us-page-wrapper > div.footer-nav-container{
    background-color: white;
}

div.contact-us-page-wrapper > div.footer-nav-container > div > a{
    color:#173269;
}

div.contact-us-page-wrapper > div.footer-nav-container > div{
    border-bottom: 2px solid #D9DADB;
    border-top: 2px solid #D9DADB;
}

.request-form-text-field{
    font-size: 3vmin;
    text-align: left;
    color: #2C2C2C;
    height: 15vh;
    padding: 1.5vh 1vw 1.5vh 1vw;
    margin-bottom:2vh;
    border-radius: 10px;
    background-color: #F2F6F8;
    border:none;
}
